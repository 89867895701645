<template>
    <van-collapse v-model="activeNames">
        <van-collapse-item v-for="item in list" :key="item.id" :border="showBorder"
                           :title="item.title" :name="item.id">
          <p v-html="item.content"></p>
        </van-collapse-item>
    </van-collapse>
</template>

<script>
    import Vue from 'vue';
    import {Collapse, CollapseItem, Loading, Empty} from 'vant';

    Vue.use(Collapse).use(CollapseItem).use(Loading).use(Empty);

    export default {
        name: "helpCenter",
        data() {
            return {
                loadingText: "暂无数据",
                activeNames: [],
                list: [],
                showBorder: true
            }
        },
        mounted() {
            this.getHelpList()
        },
        methods:{
            getHelpList(){
                this.$api.user.helpList(res =>{
                    this.list = this.list.concat(res.rows)
                    this.activeNames = this.list.map((item) => item.id)
                })
            }
        }
    }
</script>

<style scoped>
    /deep/ .van-cell__title{
        font-weight: bold;
        font-size: 36px;
    }

    /deep/ .van-collapse-item__content{
        padding: 30px 46px;
    }
</style>